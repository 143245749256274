{
"type": "FeatureCollection",
"name": "wachposten_3857",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "properties": { "fid": 8, "id": 10555, "url": "/vici/10555/", "title": "Spielberg", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Burgus Spielberg", "img": "/uploads/turmstelle_donaubrucke_spielberg.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1708578.41020078654401, 6146752.714003229513764 ] } },
{ "type": "Feature", "properties": { "fid": 11, "id": 13197, "url": "/vici/13197/", "title": "Ad Pontem Ises", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Ad Pontem Isis, mentioned on the Tabula Peutingeriana.", "img": null }, "geometry": { "type": "Point", "coordinates": [ 1678632.35398248815909, 6131577.577331077307463 ] } },
{ "type": "Feature", "properties": { "fid": 13, "id": 13341, "url": "/vici/13341/", "title": "Aggsbach", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Aggsbach", "img": "/uploads/blashauskapelle.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1713739.738391416845843, 6152900.029774218797684 ] } },
{ "type": "Feature", "properties": { "fid": 14, "id": 13342, "url": "/vici/13342/", "title": "Hollenburg", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Roman small castle.", "img": "/uploads/hollenburg_hausberg_bertholdstein.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1745531.917045030975714, 6170251.594895269721746 ] } },
{ "type": "Feature", "properties": { "fid": 15, "id": 13346, "url": "/vici/13346/", "title": "Bacharnsdorf", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 1, "identified": 1, "summary": "Bacharnsdorf", "img": "/uploads/img_1543.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1719324.748564006295055, 6168544.074283766560256 ] } },
{ "type": "Feature", "properties": { "fid": 17, "id": 13363, "url": "/vici/13363/", "title": "Windstallgraben", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 1, "identified": 1, "summary": "Windstallgraben, Rossatz-Arnsdorf", "img": "/uploads/rossatzbach_tower_anita.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1727829.780299593927339, 6170871.910522812977433 ] } },
{ "type": "Feature", "properties": { "fid": 18, "id": 13364, "url": "/vici/13364/", "title": "Sankt Lorenz", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 1, "identified": 1, "summary": "Sankt Lorenz, Rossatz-Arnsdorf", "img": "/uploads/sankt_lorenz.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1722704.630943471565843, 6172414.263282007537782 ] } },
{ "type": "Feature", "properties": { "fid": 20, "id": 13377, "url": "/vici/13377/", "title": "Maria Ponsee", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Maria Ponsee", "img": null }, "geometry": { "type": "Point", "coordinates": [ 1762278.042003535665572, 6165040.612672811374068 ] } },
{ "type": "Feature", "properties": { "fid": 26, "id": 50988, "url": "/vici/50988/", "title": "Sankt Johann im Mauerthale", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 1, "identified": 1, "summary": "Roman Watchtower", "img": "/uploads/sankt_johann_im_mauerthale_rossatz_arnsdorf_kirche.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1715414.874088874086738, 6163074.73378128092736 ] } },
{ "type": "Feature", "properties": { "fid": 33, "id": 63520, "url": "/vici/63520/", "title": "Sarling", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Römische Mauerreste unter der St. Veit Kirche.", "img": "/uploads/befundplan_bda_sarling.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1682816.297043953323737, 6135260.968884200789034 ] } },
{ "type": "Feature", "properties": { "fid": 34, "id": 63522, "url": "/vici/63522/", "title": "Säusenstein", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 1, "identified": 1, "summary": "Reste eines römischen Wachturms des norischen Limes.", "img": null }, "geometry": { "type": "Point", "coordinates": [ 1682748.726113041862845, 6138615.315247953869402 ] } },
{ "type": "Feature", "properties": { "fid": 35, "id": 17213, "url": "/vici/17213/", "title": "Greifenstein", "kind": "watchtower", "zoomsmall": 9, "zoomnormal": 13, "zindex": 5, "isvisible": 0, "identified": 1, "summary": "Wachturm Greifenstein", "img": null }, "geometry": { "type": "Point", "coordinates": [ 1808824.715967473341152, 6164875.105206625536084 ] } }
]
}
