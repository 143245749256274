{
"type": "FeatureCollection",
"name": "strasse_verlauf",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiLineString", "coordinates": [ [ [ 1755607.764769085217267, 6164652.431310046464205 ], [ 1753620.817679958883673, 6165033.661398467607796 ] ] ] } },
{ "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiLineString", "coordinates": [ [ [ 1755607.764769085217267, 6164652.431310046464205 ], [ 1758835.166252281051129, 6163242.012492840178311 ] ] ] } },
{ "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiLineString", "coordinates": [ [ [ 1758994.400634678080678, 6163197.649751048535109 ], [ 1759325.766981407767162, 6163085.075750006362796 ], [ 1759462.078474394744262, 6163057.579630422405899 ], [ 1759615.42297904053703, 6163027.355026323348284 ], [ 1759743.127891802461818, 6163014.016473814845085 ], [ 1760441.157621046993881, 6162953.390117788687348 ], [ 1760818.530557265738025, 6162922.536884267814457 ], [ 1765253.10631557693705, 6162569.612804776988924 ] ] ] } }
]
}
