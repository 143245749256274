{
"type": "FeatureCollection",
"name": "kastelle_3857",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "properties": { "fid": 1, "id": 7267, "url": "/vici/7267/", "title": "Cannabiaca (?)", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 1, "identified": 1, "summary": "Cannabiaca - Fort; 30 BC - AD 640; Pleiades #128375", "img": "/uploads/zeiselmauer_burgus_ansicht_vom_westen.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1800758.740941972471774, 6161828.58291666675359 ] } },
{ "type": "Feature", "properties": { "fid": 2, "id": 7296, "url": "/vici/7296/", "title": "Favianis", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 1, "identified": 1, "summary": "Faviana - Fort; 30 BC - AD 2100; Pleiades #118673", "img": "/uploads/befundskizze_kastell_favianis_mautern_donau_1._bis_5._jhdt.n.chr..png" }, "geometry": { "type": "Point", "coordinates": [ 1734093.839366022031754, 6172612.420760000124574 ] } },
{ "type": "Feature", "properties": { "fid": 10, "id": 12866, "url": "/vici/12866/", "title": "Comagenis", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 1, "identified": 1, "summary": "Comagena Fort", "img": "/uploads/tulln_roman_tower_abro.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1787534.096755222417414, 6162484.623009229078889 ] } },
{ "type": "Feature", "properties": { "fid": 12, "id": 13340, "url": "/vici/13340/", "title": "Ybbs an der Donau", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 0, "identified": 1, "summary": "Ybbs an der Donau", "img": "/uploads/ybbs_reste_spatantikes_kleinkastell_01.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1679286.022032426204532, 6136408.430780160240829 ] } },
{ "type": "Feature", "properties": { "fid": 21, "id": 13409, "url": "/vici/13409/", "title": "Arelape", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 0, "identified": 1, "summary": "Pöchlarn", "img": "/uploads/pochlarn_an_der_donau.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1693379.626164308516309, 6142265.988136059604585 ] } },
{ "type": "Feature", "properties": { "fid": 22, "id": 13413, "url": "/vici/13413/", "title": "Asturis", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 0, "identified": 1, "summary": "Zwentendorf an der Donau", "img": "/uploads/kastellareal_zwentendorf.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1768783.887003966839984, 6164605.460918117314577 ] } },
{ "type": "Feature", "properties": { "fid": 28, "id": 60727, "url": "/vici/60727/", "title": "Augustianis", "kind": "fort", "zoomsmall": 5, "zoomnormal": 9, "zindex": 9, "isvisible": 1, "identified": 1, "summary": "Kastell Traismauer", "img": "/uploads/reck_oder_hungerturm_von_traismauer.jpg" }, "geometry": { "type": "Point", "coordinates": [ 1752672.617101456271484, 6165264.897009369917214 ] } }
]
}
