:host {
    --ol-control-radius: var(--default-radius);
    --ol-control-background-color: rgba(200, 200, 200, 0.5);
    --ol-control-button-text-color: #555;
    --ol-control-button-background-color: #ffffff;
    --ol-control-button-text-color-hover: #000000;
    --ol-control-button-background-color-hover: #eeeeee;
    --ol-control-button-text-color-active: #000000;
    --ol-control-button-background-color-active: #dddddd;
    --ol-control-attribution-text-color: #555555;
    --ol-control-attribution-background-color: #fff;
    --ol-control-gap: .75em;
    --map-height: 500px;

    display: block;
    height: var(--map-height);
    background-color: #555;

    @extend .oag-map;
}

#key-pan-overlay {
    --foreground-color: #000;
    --symbols-height: 50px;

    z-index: 1;
    background-color: rgba(255, 255, 255, 0.75);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: auto;
    height: auto;
    padding: 1em 1.35em;
    display: flex;
    flex-direction: column;
    user-select: none;
    border-radius: var(--default-radius);
    gap: 1em;
    transition: opacity ease 200ms;

    &.visible {
        visibility: visible;
        opacity: 1;
    }

    .top {
        font-size: 1.35em;
        font-weight: 500;
        text-align: left;
        line-height: 1;
    }

    .bottom {
        display: flex;
        flex-direction: row;
        gap: 1em;

        svg {
            width: auto;
            height: var(--symbols-height);
        }

        .mouse {
            width: 1.5em;
            height: var(--symbols-height);
            box-sizing: border-box;
            border: 1.5px solid var(--foreground-color);
            position: relative;
            border-radius: 50% 50% 50% 50% / 25% 25% 25% 25%;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, 50%);
                width: 8px;
                height: 8px;
                background: var(--foreground-color);
                border-radius: 50%;
                animation: fadeDown 1s ease-out infinite;
            }

            @keyframes fadeDown {
                0% {
                    top: 0%;
                    opacity: 1;
                }

                100% {
                    top: 60%;
                    opacity: 0;
                }
            }
        }
    }
}